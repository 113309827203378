.App {
  
  overflow: hidden;
}

.header{
  top: 0;
  position: relative;
  left: 0;
  pointer-events: none; 
}

.partyDesc{
  font-family: gibbs,sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: left;
}

/* .rsvpButton {
  margin-top: 30px;
} */
.time{
  position: relative;
  font-family: cheap-pine,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2vw;
  width: 100%;
  text-align: center;
}

.title{
  position: relative;
  font-family: cheap-pine,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8vw;
  width: 100%;
  text-align: center;
}

.rsvpButton {
  float: left;
}

.calendarButton {
  float:left;
}
.directionsButton {
  float: left;
}

.buttonGroup{
  margin-top: 10px;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
}

.buttonGroup2{
  margin-top: 10px;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5%;
}


@media (max-width:1200px){
  .header{
    width: 1200px;
  }
  .combinedTitle {
    width: 100%;
    justify-content: center;
    display: inline-block;
    height: 100%;
    top: -10%;
    position: relative;
  }
  .title{
    margin-top: -22%;
    font-size: 15vw;
  }
  .firework{
    width: 30%;
    margin-left: 65%;
    margin-top: -7%;
  }
  .partyDesc{
    width: 95%;
    margin-left: 2.5%;
    font-size: 5vw;
  }
  .buttonGroup{
    width: 80%;
    margin-left: 10%;
  }
  .buttonGroup2{
    width: 80%;
    margin-left: 10%;
    grid-template-columns: 1fr;
  }
  .rightFirstSection{
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .time{
    font-size: 8vw;
    margin-top: -10px;
  }
}

@media (min-width:1201px){
  .firstSection{
    margin-top: 10px;
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    gap: 0%;
  }
  .header {
    width: 100%;
  }
  .combinedTitle {
    width: 100%;
    left: 5%;
    position: relative;
    top: 0%;
  }
  .firework{
    width: 35%;
    margin-left: 70%;
    margin-top: -10%;
    z-index:0;
  }
  .title{
    margin-top: -25%;
    font-size: 8vw;
  }
  .partyDesc{
    width: 80%;
    margin-top: 10px;
    margin-left: 20%;
    font-size: 1.5vw;
  }
  .buttonGroup{
    width: 50%;
    margin-top: 15px;
    margin-left: 25%;
  }
  .leftFirstSection{
    float: left;
  }
  .rightFirstSection{
    float: left;
    z-index:1;
  }
  .time{
    margin-top: -40px;
    margin-left: 40px;
  }
}

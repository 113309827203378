.formHolder {
  width: 80%;
  margin: 5% 10% 0 10%;
  font-family: "Montserrat",Sans-serif;
}

.rsvpTitle{
  position: relative;
  font-family: cheap-pine,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10vw;
  width: 100%;
  text-align: center;
  margin-bottom: -40px;
}

.rsvpBody{
  overflow: hidden;
}